var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Iloc3bjoHBZqpn3vNTlwX"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/app/1";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

//import * as Sentry from '@sentry/nextjs';

//const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY;

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

if (process.env.SENTRY_AUTH_TOKEN) {
  Sentry.init({
    normalizeDepth: 6,
    dsn: publicRuntimeConfig.sentryDsn,
    tracesSampleRate: 1.0,
    beforeSend: (event, hint) => {
      if (hint.originalException instanceof Error) {
        return event;
      }

      return null;
    },
  });
}
